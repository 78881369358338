
const BASE_URL = "https://api.naka.co.in/";
export const PROFILE_PIC_URL = `https://api.naka.co.in/`;

// const BASE_URL = "http://127.0.0.1:8000/";
// export const PROFILE_PIC_URL = "http://127.0.0.1:8000/"; 


export const LOGIN_URL =  `${BASE_URL}api/admin_login`;
export const LOGOUT_URL = `${BASE_URL}api/logout`;
export const ALL_USER = `${BASE_URL}api/users/get_all_users`;
//export const JOBS_LIST = `${BASE_URL}api/jobs/get_jobs`;
export const JOBS_LIST = `${BASE_URL}api/jobs/admin_get_jobs`;
 

export const JOB_DETAILS = `${BASE_URL}api/jobs/get_jobs_details/`;
export const ATTRIBUTES_ALL = `${BASE_URL}api/jobs/get_all`;
export const CREATE_JOB = `${BASE_URL}api/jobs/create_update`;
export const GET_COMPNAY = `${BASE_URL}api/jobs/get_company`;
export const CREATE_JOB_POSITION = `${BASE_URL}api/attributes/position_add_update`;
export const GET_JOB_POSITION = `${BASE_URL}api/attributes/get_job_position/`;
export const CREATE_EMP_TYPE = `${BASE_URL}api/attributes/emp_type_add_update`;
export const GET_EMP_TYPE = `${BASE_URL}api/attributes/emp_type/`;
export const CREATE_INDUSTRY_TYPE = `${BASE_URL}api/attributes/industry_type_add_update`;
export const GET_INDUSTRY_TYPE = `${BASE_URL}api/attributes/industry_type/`;
export const CREATE_SKILLS = `${BASE_URL}api/attributes/skills_add_update`;
export const GET_SKILLS = `${BASE_URL}api/attributes/get_skills/`;
export const CREATE_EXPERIENCE = `${BASE_URL}api/attributes/experiance_add_update`;
export const GET_EXPERIENCE = `${BASE_URL}api/attributes/get_experiance/`; 
export const CREATE_EDUCATION = `${BASE_URL}api/attributes/education_add_update`;
export const GET_EDUCATION = `${BASE_URL}api/attributes/get_experiance/`;
export const CREATE_WORKPLACE = `${BASE_URL}api/attributes/workplace_add_update`;
export const GET_WORKPLACE = `${BASE_URL}api/attributes/get_workplace/`;
export const CREATE_STATE = `${BASE_URL}api/attributes/state_add_update`;
export const GET_STATE = `${BASE_URL}api/attributes/get_state/`;
export const CREATE_CITY = `${BASE_URL}api/attributes/city_add_update`;
export const GET_CITY = `${BASE_URL}api/attributes/get_city/`;
export const GET_CITY_LIST = `${BASE_URL}api/attributes/city_list`;
export const CREATE_SALARY_TYPE = `${BASE_URL}api/attributes/salary_type_add_update`;
export const GET_SALARY_TYPE = `${BASE_URL}api/attributes/get_salary_type/`;
export const CREATE_PROMOTE = `${BASE_URL}api/attributes/promote_add_update`;
export const GET_PROMOTE = `${BASE_URL}api/attributes/get_promote/`;
export const GET_ADMIN_JOB_APPLYED = `${BASE_URL}api/jobs/get_applyed_job_admin`;
export const JOB_DETAILS_ADMIN = `${BASE_URL}api/jobs/get_applyed_job_details_admin/`;
export const GET_CANDIDATES = `${BASE_URL}api/get_candidates`;
export const GET_CANDIDATES_DETAILS = `${BASE_URL}api/candidates_details/`;
export const CREATE_CANDIDATES = `${BASE_URL}api/create_candidate`;
export const UPDATE_CANDIDATE = `${BASE_URL}api/create_candidate`;


export const UPDATE_PROFILE_PIC = `${BASE_URL}api/update_profile_pic`;



export const GET_CANDIDATES_EDIT = `${BASE_URL}api/get_candidates_edit/`;
export const DELETE_CANDIDATE = `${BASE_URL}api/delete_candidate/`;
export const GET_EMPLOYER = `${BASE_URL}api/get_employer`;
export const GET_EMPLOYER_DETAILS = `${BASE_URL}api/employer_details/`;
export const CREATE_EMPLOYER = `${BASE_URL}api/employer_register_admin`;
export const EDIT_EMPLOYER = `${BASE_URL}api/employer/update/`;
export const GET_EMPLOYER_EDIT = `${BASE_URL}api/get_employer_edit/`; 
export const GET_EMP_JOB = `${BASE_URL}api/admin_get_emp_jobs/`;
export const DELETE_STATE = `${BASE_URL}api/attributes/delete_state/`;
export const DELETE_CITY = `${BASE_URL}api/attributes/delete_city/`;
export const DELETE_SALARY_TYPE = `${BASE_URL}api/attributes/delete_salarytype/`;
export const DELETE_INDUSTRY_TYPE = `${BASE_URL}api/attributes/delete_industrytype/`;
export const DELETE_JOB_POSITION = `${BASE_URL}api/attributes/delete_job_position/`;
export const DELETE_EMP_TYPE = `${BASE_URL}api/attributes/delete_emp_type/`;
export const DELETE_SKILL = `${BASE_URL}api/attributes/delete_skill/`;
export const DELETE_EXPERIANCE = `${BASE_URL}api/attributes/delete_experiance/`;
export const DELETE_EDUCATION = `${BASE_URL}api/attributes/delete_educations/`; 
 export const DELETE_WORKPLACE = `${BASE_URL}api/attributes/delete_workplace/`;
 export const DELETE_PROMOTE = `${BASE_URL}api/attributes/delete_promote/`;
 export const DELETE_JOB = `${BASE_URL}api/attributes/delete_job/`; 
export const GET_PAGES_LIST = `${BASE_URL}api/attributes/page_list`;
export const PAGES_CREATE_UPDATE = `${BASE_URL}api/attributes/pages_add_update`;
export const DELETE_PAGE = `${BASE_URL}api/attributes/delete_page/`;
export const GET_PAGE_EDIT = `${BASE_URL}api/attributes/get_page/`;
export const PAGES_UPDATE = `${BASE_URL}api/attributes/pages_update/`;
export const ACCOUNT_STATUS = `${BASE_URL}api/users/account_status_change/`;

export const JOB_STATUS = `${BASE_URL}api/users/job_status_change/`;

export const EDIT_JOB = `${BASE_URL}api/jobs/get_edit_jobs/`;
export const GET_EMAIL_TEMPLATE_EDIT = `${BASE_URL}api/attributes/get_email_template/`;
export const EMAIL_TEMPLATE_UPDATE = `${BASE_URL}api/attributes/email_template_update`;

export const GET_DASHBOARD_COUNT = `${BASE_URL}api/dashboard/get_dashboard`;


 



 

 
 
 
 






 
 

 
